import { Button } from "@twilio-paste/core";
import log from "loglevel";
import { useSelector } from "react-redux";

import { AppState } from "../store/definitions";

export const MessageButton = ({ label, onClick }: { label: string; onClick?: () => void }) => {
    const { conversation } = useSelector((state: AppState) => ({
        conversation: state.chat.conversation
    }));
    const send = async () => {
        if (!conversation) {
            log.error("Failed sending message: no conversation found");
            return;
        }
        let preparedMessage = conversation.prepareMessage();
        preparedMessage = preparedMessage.setBody(label);
        await preparedMessage.build().send();
        onClick?.();
    };
    return (
        <Button variant="primary" size="small" css={{ marginTop: "4px" }} onClick={send}>
            {label}
        </Button>
    );
};
