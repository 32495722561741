import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { AppState, EngagementPhase } from "../store/definitions";
import { PreEngagementFormPhase } from "./PreEngagementFormPhase";
import { LoadingPhase } from "./LoadingPhase";
import { EntryPoint } from "./EntryPoint";
import {
    innerContainerStyles,
    innerEmbeddedContainerStyles,
    outerContainerStyles,
    outerEmbeddedContainerStyles
} from "./styles/RootContainer.styles";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};

export function RootContainer() {
    const { currentPhase, expanded } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));

    const embedded = useSelector(({ config }: AppState) => config.embedded);

    const outer = embedded ? outerEmbeddedContainerStyles : outerContainerStyles;
    const inner = embedded ? innerEmbeddedContainerStyles : innerContainerStyles;

    return (
        <Box>
            <Box {...outer} style={{ bottom: "6.5rem" }}>
                {expanded && (
                    <Box data-test="root-container" {...inner}>
                        {getPhaseComponent(currentPhase)}
                    </Box>
                )}
                <EntryPoint />
            </Box>
        </Box>
    );
}
