import merge from "lodash.merge";
import { render } from "react-dom";
import { Provider } from "react-redux";

import { WebchatWidget } from "./components/WebchatWidget";
import { initLogger } from "./logger";
import { sessionDataHandler } from "./sessionDataHandler";
import { initConfig } from "./store/actions/initActions";
import { ConfigState } from "./store/definitions";
import { store } from "./store/store";

const PRIMARY_COLOR = "rgba(137, 217, 157, 0.6)";

const defaultConfig: ConfigState = {
    serverUrl: "http://localhost:5000",
    theme: {
        isLight: true,
        overrides: {
            backgroundColors: {
                colorBackgroundStronger: PRIMARY_COLOR,
                colorBackgroundPrimary: "#3B8C6E",
                colorBackgroundPrimaryStrong: "#035959",
                colorBackgroundPrimaryStronger: "#035959",
                colorBackgroundPrimaryStrongest: "#035959",
                colorBackgroundBrand: "#3B8C6E",
                colorBackgroundBrandStrong: "#035959",
                colorBackgroundBrandStronger: "#035959"
            },
            borderColors: {
                colorBorder: PRIMARY_COLOR,
                colorBorderInverse: PRIMARY_COLOR,
                colorBorderNeutral: "#3B8C6E",
                colorBorderPrimary: "#3B8C6E",
                colorBorderPrimaryStrong: "#035959",
                colorBorderPrimaryStronger: "#035959",
                colorBorderPrimaryStrongest: "#035959"
            },
            textColors: {
                colorTextIconInverse: PRIMARY_COLOR,
                colorTextInverseWeaker: PRIMARY_COLOR,
                colorTextLink: "#3B8C6E",
                colorTextLinkLight: "#3B8C6E",
                colorTextLinkStrong: "#035959",
                colorTextLinkStronger: "#035959",
                colorTextLinkStrongest: "#035959",
                colorTextLinkWeak: "#3B8C6E"
            },
            shadows: {
                shadowFocus: "0 0 0 4px rgba(59, 140, 110, 0.7)",
                shadowFocusInset: "inset 0 0 0 2px rgba(59, 140, 110, 0.7)"
            }
        }
    },
    fileAttachment: {
        enabled: true,
        maxFileSize: 16777216, // 16 MB
        acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt", "mpga"]
    },
    transcript: {
        downloadEnabled: false,
        emailEnabled: false,
        emailSubject: (agentNames) => {
            let subject = "Transcript of your chat";
            if (agentNames.length > 0) {
                subject = subject.concat(` with ${agentNames[0]}`);
                agentNames.slice(1).forEach((name) => (subject = subject.concat(` and ${name}`)));
            }
            return subject;
        },
        emailContent: (customerName, transcript) => {
            return `<div><h1 style="text-align:center;">Chat Transcript</h1><p>Hello ${customerName},<br><br>Please see below your transcript, with any associated files attached, as requested.<br><br>${transcript}</p></div>`;
        }
    }
};

const initWebchat = async (config: ConfigState) => {
    const rootElement = document.getElementById("twilio-webchat-widget-root");
    const mergedConfig = merge({}, defaultConfig, config);
    sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
    store.dispatch(initConfig(mergedConfig));
    initLogger();

    render(
        <Provider store={store}>
            <WebchatWidget />
        </Provider>,
        rootElement
    );
};

declare global {
    interface Window {
        Twilio: {
            initWebchat: (config: ConfigState) => void;
        };
        store: typeof store;
    }
}

// Expose `initWebchat` function to window object
Object.assign(window, {
    Twilio: {
        initWebchat
    }
});
