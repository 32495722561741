import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { useDispatch } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { containerStyles, titleStyles } from "./styles/Header.styles";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const dispatch = useDispatch();
    const closeChat = () => dispatch(changeExpandedStatus({ expanded: false }));

    return (
        <Box as="header" {...containerStyles}>
            <Text as="h2" {...titleStyles}>
                {customTitle || "Live Chat"}
            </Text>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                onClick={closeChat}
                style={{
                    width: 24,
                    height: 24,
                    color: "white",
                    cursor: "pointer"
                }}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </Box>
    );
};
