import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { containerEmbeddedStyles, containerStyles } from "./styles/EntryPoint.styles";

const addListener = (topic: string, events: string[], onChange: (payload: unknown) => void) => {
    window.addEventListener("message", async (msg) => {
        if (!msg) return;
        const windowMessage = msg.data;
        if (windowMessage.topic !== topic) return;
        if (events.includes(windowMessage.event)) onChange?.(windowMessage.payload);
    });
};

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);
    const embedded = useSelector(({ config }: AppState) => config.embedded);

    const container = embedded ? containerEmbeddedStyles : containerStyles;

    useEffect(() => {
        addListener("twilio-chat", ["chat-open"], () => dispatch(changeExpandedStatus({ expanded: true })));
    }, [dispatch]);

    return (
        <Box
            as="button"
            data-test="entry-point-button"
            onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
            {...container}
            style={{
                width: 56,
                height: 56
            }}
        >
            {expanded ? (
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" />
            ) : (
                <ChatIcon decorative={false} title="Open chat" size="sizeIcon80" />
            )}
        </Box>
    );
};
