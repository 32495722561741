import log from "loglevel";

import { CreateWebChatResponse, RefreshWebChatTokenResponse, Token } from "./definitions";

interface OptionToken extends Token {
    embedded?: boolean;
}

const LOCAL_STORAGE_ITEM_ID = "TWILIO_WEBCHAT_WIDGET";

let _endpoint = "";

export async function contactBackend<T>(endpointRoute: string, body: Record<string, unknown> = {}): Promise<T> {
    const response = await fetch(_endpoint + endpointRoute, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error("Request to backend failed");
    }

    return response.json();
}

function storeSessionData(data: Token) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_ID, JSON.stringify(data));
}

function getStoredSessionData() {
    const item = localStorage.getItem(LOCAL_STORAGE_ITEM_ID);
    let storedData: Token;

    if (!item) {
        return null;
    }

    try {
        storedData = JSON.parse(item);
    } catch (e) {
        log.log("Couldn't parse locally stored data");
        return null;
    }

    return storedData;
}

async function getRedirectedSessionData() {
    try {
        const search = window?.location?.search;
        if (search) {
            const params = new URLSearchParams(search);
            const opportunityId = params.get("id");
            if (!opportunityId) return undefined;
            const opportunityReq = await fetch(`${_endpoint}/crm/landing/${opportunityId}`);

            const opportunity = await opportunityReq.json();

            if (opportunityId && opportunity.data) {
                return {
                    opportunityId,
                    friendlyName: `${opportunity.data.first_name} ${opportunity.data.last_name}`,
                    email: opportunity.data.email,
                    query: "Hi, I'd like to know more about Liftup!"
                };
            }
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
}

export const sessionDataHandler = {
    setEndpoint(endpoint: string = "") {
        _endpoint = endpoint;
    },

    getEndpoint() {
        return _endpoint;
    },

    async tryResumeExistingSession(): Promise<OptionToken | null> {
        log.debug("sessionDataHandler: check for redirected opportunities");
        const redirectedData = await getRedirectedSessionData();

        if (redirectedData) {
            const data = await this.fetchAndStoreNewSession({
                formData: redirectedData
            });
            return {
                ...data,
                embedded: true
            };
        }

        log.debug("sessionDataHandler: trying to refresh existing session");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            log.debug("sessionDataHandler: no tokens stored, no session to refresh");
            return null;
        }

        if (Date.now() >= new Date(storedTokenData.expiration).getTime()) {
            log.debug("sessionDataHandler: token expired, ignoring existing sessions");
            return null;
        }

        log.debug("sessionDataHandler: existing token still valid, using existing session data");
        return storedTokenData;
    },

    async getUpdatedToken(): Promise<Token> {
        log.debug("sessionDataHandler: trying to get updated token from BE");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            throw Error("Can't update token: current token doesn't exist");
        }

        let newTokenData: Token;

        try {
            const response = await contactBackend<RefreshWebChatTokenResponse>("/callcenter/chat/refresh", {
                token: storedTokenData.token
            });
            newTokenData = {
                token: response.token,
                expiration: response.expiration.toString(),
                conversationSid: "",
                identity: ""
            };
        } catch (e) {
            throw Error(`Something went wrong when trying to get an updated token: ${e}`);
        }

        // Server won't return a conversation SID, so we merge the existing data with the latest one
        const updatedSessionData = {
            ...storedTokenData,
            ...newTokenData
        };

        storeSessionData(updatedSessionData);

        return updatedSessionData;
    },

    fetchAndStoreNewSession: async ({ formData }: { formData: Record<string, unknown> }) => {
        log.debug("sessionDataHandler: trying to create new session");

        const { friendlyName, opportunityId, email, query } = formData;
        console.log(`🚀 ~ formData:`, formData);

        let newTokenData: Token;

        try {
            const response = await contactBackend<CreateWebChatResponse>("/callcenter/chat/init", {
                name: friendlyName,
                // eslint-disable-next-line
                opportunity_id: opportunityId,
                email,
                question: query,
                channel: "WEBCHAT"
            });
            newTokenData = {
                token: response.token,
                conversationSid: response.conversation_sid,
                expiration: response.expiration.toString(),
                identity: ""
            };
        } catch (e) {
            throw Error("No results from server");
        }

        log.debug("sessionDataHandler: new session successfully created");
        storeSessionData(newTokenData);

        return newTokenData;
    },

    clear: () => {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_ID);
    }
};
